import React from "react";

function NotFoundPage() {
  return (
    <div className="grid h-screen place-items-center">
      존재하지 않는 페이지입니다.
    </div>
  );
}

export default NotFoundPage;
